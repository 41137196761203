@font-face {
    src: url("./assets/fonts/AmazonEmber/Amazon-Ember-Medium.ttf");
    font-family: 'Amazon Ember' !important;
}

:root{
--font-family-base-dnvic8: Tahoma, sans-serif !important;
}

body {
    margin: 0;
    font-family: Amazon Ember, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #fafafa;
}

.btn-orange {
    background-color: #EB5F07 ;
    border-color: #EB5F07 ;
}

.btn-blue-light {
    background-color: #18bfe9 ;
    border-color: #18bfe9 ;
}

.rotate-180 {
    transform: rotate(180deg);
}

.space-20 {
    height: 20px;
}

.space-40 {
    height: 40px;
}

.space-60 {
    height: 60px;
}

.board {
    box-shadow: 0px 1px 2px rgba(0, 28, 36, 0.6);
}

.board-header {
    background-color: #FAFAFA;
    height: 62px;
    font-size: 18px;
    font-weight: bold;
    padding: 20px;
    border-bottom: solid 1px #EAEDED;
}

.board-content {
    background-color: white !important;
    padding: 20px;
    font-size: 14px;
}

.board-footer {
    height: 56px;
    border-top: solid 1px #EAEDED;
    text-align: right;
    padding: 12px 20px 12px 20px;
}

.text-bold {
    font-weight: bold;
}