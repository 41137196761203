.footer {
    height: 30px;
    background-color: #232F3E;
    padding: 0 20px;
}

.footer-div {
    margin-top: -8px;
    display: inline-block;
}

.footer-div-left {
    width: 20%;
}

.footer-div-right {
    text-align: right;
    width: 80%;
}

.footer span {
    color: #AAB7B8;
    font-size: 12px;
}

.footer a {
    color: #D5DBDB;
    text-decoration: none;
    font-size: 12px;
}

.footer-div-right a {
    margin-left: 40px;
}

@media only screen and (max-width: 768px) {
    .footer {
        padding: 0 8px;
    }
    .footer a{
        font-size: 7px;
    }
    .footer span{
        font-size: 7px;
    }
    .footer-div-right a {
        margin-left: 5px;
    }
}